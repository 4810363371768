.active .Mui-checked + .MuiFormControlLabel-label {
   color: #ffffff;
   background-color: #129b00;
}

.MuiFormControlLabel-label .alert {
   display: none;
}

.active .Mui-checked + .MuiFormControlLabel-label .alert {
   display: block;
}

.tie .Mui-checked + .MuiFormControlLabel-label .alert {
   display: block;
}

.active .MuiFormControlLabel-label {
   color: #e5e5e5 !important;
}

.active .MuiRadio-colorPrimary {
   color: #e5e5e5 !important;
}

.disabled .MuiFormControlLabel-label {
   color: #e5e5e5;
}

.disabled .MuiRadio-colorPrimary {
   color: #e5e5e5 !important;
}

.tie .Mui-checked + .MuiFormControlLabel-label {
   color: #ffffff;
   background-color: #ff7f00;
}

.tie .MuiFormControlLabel-label {
   color: #e5e5e5;
}

.tie .MuiRadio-colorPrimary {
   color: #e5e5e5 !important;
}

.MuiFormControlLabel-label {
   padding: 0 1em;
   font-weight: bold !important;
}
