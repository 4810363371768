.tierlist {
   position: absolute;
   display: block;
   font-size: 3em;
   z-index: 100;
   color: #ffffff;
   font-size: 2em;
   padding: 0;
   box-sizing: border-box;
}

.tierlistSingle {
   position: absolute;
   z-index: 50;
   display: block;
   padding: 0;
   width: 580px;
   height: 400px;
   border: 7px solid #dc4db7;
   box-shadow: 0 0 15px #dc4db7;
   border-radius: 7px;
   left: 80px;
   top: 234px;
   background-color: #000;
}

.tierlistSingle img {
   width: 100%;
   height: 100%;
   display: block;
}

.tierlistAll {
   position: absolute;
   z-index: 100;
   scale: 1;
}

.tierlistAll .slot {
   color: #fff;
   height: 143px;
   width: 1078px;
   display: block;
   position: relative;
   padding: 20px 0 0 180px;
   box-sizing: border-box;
   z-index: -1;
   overflow: hidden;
   white-space: nowrap;
   mask-image: linear-gradient(to right, black 90%, transparent 100%);
}

.tierlistAll .slot .item {
   display: inline-block;
   margin-right: 10px;
   box-sizing: border-box;
   height: 105px;
   width: 105px;
   overflow: hidden;
   z-index: 100;
   border-radius: 6px;
}

.primaryImage img {
   position: relative;
   left: 50%;
   transform: translateX(-50%);
}

.tierlistAll .slot .item img {
   position: relative;
   height: 105px;
   transform: translateX(-21px);
}

.slotTitle {
   display: block;
   background-color: #333;
   color: #fff;
   font-weight: bold;
   font-size: 1.2em;
   padding: 0.5em 1em;
   margin: 1em 0 0 0;
}

.slot.rowA {
   /* background-image: url("./images/row_A.png"); */
}

.slot.rowB {
   /* background-image: url("./images/row_B.png"); */
}

.slot.rowC {
   /* background-image: url("./images/row_C.png"); */
}

.slot.rowD {
   /* background-image: url("./images/row_D.png"); */
}

.slot.rowF {
   /* background-image: url("./images/row_F.png"); */
}

.slot.rowS {
   /* background-image: url("./images/row_S.png"); */
}

.tierlistData .row {
   width: 100%;
   height: auto;
   margin: auto;
   padding: 0;
   text-align: left;
   position: absolute;
   overflow: hidden;
   border: 1px solid #fff;
   box-sizing: border-box;
}

.tierlistRow {
   padding: 1em;
   margin: 0 0 5px 0;
   background-color: #222;
   cursor: pointer;
   display: block;
   position: relative;
}

.tierlistRow img {
   width: 150px;
   vertical-align: middle;
}
