* {
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: -moz-none;
   -o-user-select: none;
   user-select: none;
}

input {
   -webkit-user-select: text;
   -khtml-user-select: text;
   -moz-user-select: text;
   -o-user-select: text;
   user-select: text;
}

img {
   pointer-events: none;
}

body {
   margin: 0;
   padding: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: transparent;
}

ul {
   list-style: none;
   padding: 0;
}

ol {
   list-style: revert;
   padding: 0;
}

li {
   margin: 2px;
   padding: 0.5em 1em;
   border: 1px solid #ccc;
   display: flex;
   flex-direction: row;
   align-items: center;
   overflow: hidden;
}

.activeTimer,
.activeTugofWar {
   background-color: rgba(34, 97, 0, 0.431) !important;
}

.emoteBox {
   cursor: pointer;
}

.emoteBox:hover {
   background-color: #efefef;
}

.viewerTable {
   font-size: 2em;
}

.bgTransparency {
   background-image: linear-gradient(
         45deg,
         #444 25%,
         transparent 25%,
         transparent 75%,
         #444 75%
      ),
      linear-gradient(45deg, #444 25%, #333 25%, #333 75%, #444 75%);
   background-size: 40px 40px;
   background-position: 0px 0px, 20px 20px;
}

.wheelcontainer {
   position: relative;
   overflow: hidden;
   z-index: 100;
   margin: 50px;
}

.wheeloverlay {
   width: 100%;
   height: 100%;
   position: absolute;
   z-index: 100;
   top: 0;
   left: 0;
   background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 30%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.2) 100%
   );
}

.wheel {
   perspective: 10000;
   padding: 0;
   margin: 0;
   position: absolute;
   top: 0;
   left: 0;
}
.wheel__inner {
   position: relative;
   margin: 0 auto;
   transform-style: preserve-3d;
}
.wheel__segment {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   position: absolute;
   top: 50%;
   opacity: 1;
   outline: 1px solid rgba(0, 0, 0, 0.5);
}
.wheel__segment:nth-child(even) {
   background-color: #ddd;
}

.result {
   border-left: 10px solid #ff0000;
   padding-left: 0.5em;
}

.layoutDataOuterTop {
   position: absolute;
   font-size: 12px;
   color: #fff;
   background-color: rgba(0, 0, 0, 0.7);
   padding: 1em;
   font-family: Arial;
   top: -48px;
   right: -8px;
}

.layoutDataOuter {
   position: absolute;
   font-size: 12px;
   color: #fff;
   background-color: rgba(0, 0, 0, 0.7);
   padding: 1em;
   font-family: Arial;
   bottom: -48px;
   right: -8px;
}

.layoutDataInner {
   position: absolute;
   font-size: 12px;
   color: #fff;
   background-color: rgba(0, 0, 0, 0.3);
   padding: 1em;
   font-family: Arial;
   top: 0;
   left: 0;
   -webkit-text-stroke: transparent;
}

.rndComponent .handles {
   top: 0;
   left: 0;
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: 1000;
   opacity: 0;
}

.rndComponent:hover .handles {
   opacity: 1;
}

.handles svg {
   width: 20px;
   height: 20px;
   position: absolute;
   transform-origin: center center;
}

.top svg {
   top: 0;
   left: 50%;
   transform: translateX(-50%) rotate(-90deg);
}

.right svg {
   top: 50%;
   right: 0;
   transform: translateY(-50%) rotate(0);
}

.bottom svg {
   bottom: 0;
   left: 50%;
   transform: translateX(-50%) rotate(90deg);
}

.left svg {
   position: relative;
   top: 50%;
   transform: translateY(-50%) rotate(-180deg);
}

.topLeft svg {
   top: 5px;
   left: 5px;
   transform: rotate(-135deg);
}

.topRight svg {
   top: 5px;
   right: 5px;
   transform: rotate(-45deg);
}

.bottomRight svg {
   top: -5px;
   right: 5px;
   transform: rotate(45deg);
}

.bottomLeft svg {
   top: -5px;
   left: 5px;
   transform: rotate(135deg);
}

.MuiTab-root.Mui-selected {
   color: red !important;
}

.MuiTabs-indicator {
   background-color: red !important;
}

.MuiTabs-flexContainer {
}

.tab {
   min-width: 0 !important;
   padding: 0 1em !important;
}

.editMode .outline {
   border: 1px solid rgba(255, 212, 0, 0.547);
}
